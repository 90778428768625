import {
  Facet
} from "@elastic/react-search-ui";
import { useState } from "react";
import CustomMultiFacet from "./CustomMultiFacet";
import CustomMultiFacetTags from "./CustomMultiFacetTags";
import TagButton from "../searchBar/TagButton";
  

// Facets displayed before expand is pressed on search results filtering
const DefaultFacets = (props) => {

  // The facet view for the secondary category of tags (greyer color scheme)
  const CustomMultiFacetTagsSecondary = (props) => {
    return <CustomMultiFacetTags secondary={true} {...props}/>
  }

  return <>
      <Facet
        field="content_issue"
        label="Issue"
        view={CustomMultiFacetTags}
        filterType="any"
        show={30} //amount to show before a show more button is displayed
        // isFilterable={true} // allow for searching through tag
      />
      <Facet
        field="content_support"
        label="Support Type"
        view={CustomMultiFacetTagsSecondary}
        filterType="any"
        show={30}
      />
    </>
}

// Facets displayed with detailed filtering
const ExpandedFacets = (props) => {
    return <>
      <Facet
        field="content_issue"
        label="Issue"
        view={CustomMultiFacet}
        filterType="any"
        show={30}
      />
      <Facet
        field="content_support"
        label="Support Type"
        view={CustomMultiFacet}
        filterType="any"
        show={30}
      />
      <Facet
        field="content_type"
        label="Content Type"
        view={CustomMultiFacet}
        filterType="any"
        show={30}
      />
      <Facet
        field="content_severity"
        label="Severity"
        view={CustomMultiFacet}
        filterType="any"
        show={30}
      />
      <Facet
        field="content_audience"
        label="Audience"
        view={CustomMultiFacet}
        filterType="any"
        show={30}
      />
    </>
  }



// Facet view modal
const Filtering = (props) => {

  const [expanded, setExpanded] = useState(false);
  const toggleExpanded = () => {
    setExpanded(!expanded);
  }

    return <div className="facets">
      {expanded? <ExpandedFacets/> : <DefaultFacets/>}
      <TagButton value={expanded ? '⇧' :'➕'} checked={expanded} onChangeFunc={toggleExpanded}/>
    </div>
}

export default Filtering