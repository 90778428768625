import VideoItem from "./VideoItem";
import ShowMoreWrapper from "../utility/ShowMoreWrapper"

function VideoGroup(props){
    return ( 
            <div className="video-container">
                {/* <span className="resource-name" id="videos">Videos ({props.videos.length})</span> */}
                <h4>Videos {props.hideCount? null: `(${props.videos.length})`}</h4>
                <div className="video-tiles row">
                {/* <div className="video-tiles-horizontal"> */}
                    <ShowMoreWrapper initial={props.initial? props.initial : 5} group={props.videos} Component={VideoItem} message="Videos "/>
                </div>
            </div>
    )
}

export default VideoGroup;