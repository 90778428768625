function OrganisationItem(props){
    // const imagePath = require("../../resources/images/" + props.content_org_logo);
    const imagePath =  props.content_org_logo;

    const phoneImage = require("../../resources/images/phone.png");
    const websiteImage = require("../../resources/images/site.png");
    const emailImage = require("../../resources/images/mail.png");

    const contactButtons = []

    if (props.content_org_phone_no){
        contactButtons.push(
            <a href={"tel:" + props.content_org_phone_no}><img alt="Phone Icon" src={phoneImage} width="35"></img></a>
        )
    }

    if (props.content_org_home_page){
        contactButtons.push(
            <a href={props.content_org_home_page} ><img alt="Website Icon" src={websiteImage} width="35"></img></a>
        )
    }

    if (props.content_org_email){
        contactButtons.push(
            <a href={"mailto:" + props.content_org_email} ><img alt="Email Icon" src={emailImage} width="35"></img> </a>
        )
    }

    return (
        <div className="orgBox" onClick={()=> window.location.href = props.wordpress_url}>
            <img src={imagePath} className="orgImage" alt="organisationLogo"></img>
            <a href={props.wordpress_url}><h3>{props.content_org_name}</h3></a>
            <p className="description"> {props.content_org_description} </p>
            <div className="contact-buttons">
                {contactButtons}
            </div>
        </div>

    )
}

export default OrganisationItem;