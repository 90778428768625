function SearchBarInput(props) {

    return (<div className="search-bar">
        <form onSubmit={props.onSubmit} className="text-entry">
            <input id="search-box" 
                    type="text" 
                    placeholder="What are you looking for?" 
                    name="q" 
                    value={props.value} 
                    onChange={(e) => props.onChange(e.currentTarget.value)}
            />
            <button type="button" id="find-help" onClick={props.findHelpModal}>Find help</button>
            <button type="button" id="toggle" onClick={props.experiencesModal}>I'm experiencing</button>
            <button type="submit" id="search-button"><i className="fa fa-search"></i></button>
        </form>
    </div>)
}

export default SearchBarInput;