import OrganisationItem from "./OrganisationItem"
import ShowMoreWrapper from "../utility/ShowMoreWrapper";

function OrganisationGroup(props){

    if (props.text){
        var text = <div className="contact-description">
            <p> <span> {props.text}</span></p>
        </div>  
    }


    return (
        <div className="contact-wrapper" id="supportServices">
            <div className="contact-section">
                <h3> {props.hideCount? null: props.orgs.length} Support organisations to meet your needs </h3>

                {text}       

                <div className="grid-container">
                    <ShowMoreWrapper group={props.orgs} initial={props.initial? props.initial: 5} Component={OrganisationItem} message="Organisations "/>
                </div>

            </div>
        </div>
    )


}

export default OrganisationGroup;