import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import TagButton from "./TagButton";
import ClickToSearchWrapper from '../utility/ClickToSearchWrapper';

export default function ImExperiencingModal(props) {
    const { experiences, profiles } = props;


    return(
    <aside>
        <div className="checkbox-all">
            <Tabs defaultActiveKey="first">
            <Tab eventKey="first" title="I'm experiencing">

            <fieldset>
                    <legend className="checkbox-header"> I'm experiencing </legend>
                    {experiences.map(x => <ClickToSearchWrapper value={x} keyword={x} Component={TagButton}/>)}
                </fieldset>
                </Tab>
                <Tab eventKey="second" title="Profiles">
                <fieldset>
                    <legend className="checkbox-header" id="profile"> Profile </legend>
                    {profiles.map(x => <ClickToSearchWrapper value={x} keyword={x} Component={TagButton}/>)}
                </fieldset>
                </Tab>

            </Tabs>

            </div>
    </aside>
    );
}