import React from 'react';
import { buildArticleProps, buildOrganisationProps, buildVideoProps } from './ElasticFieldnameMaps';
import ResultsLayout from './ResultsLayout';


// Is implemented as an elastic search UI Results view, given elastic results (prop.children):
// Group on the type of content, map the elastic fieldnames to props and pass to layout to build resource groups.
// https://docs.elastic.co/search-ui/api/react/components/results

function groupResultsByType(results){

    const reducer = (groups, value) => {
        var contentType = value.props.result.content_type?.raw

        if (!groups[contentType]) {
          groups[contentType] = [];
        }
    
        groups[contentType].push(value.props.result);
      
        return groups;
      }

    return results.reduce(reducer, {});
}


function ResultsHandler(props){
    
    const groupings = groupResultsByType(props.children)

    return <ResultsLayout
                videosProps={groupings.Video? buildVideoProps(groupings.Video):null}
                orgsProps={groupings.Organisation? buildOrganisationProps(groupings.Organisation):null}
                articlesProps={groupings.Article? buildArticleProps(groupings.Article): null}
            />;
}

export default ResultsHandler;