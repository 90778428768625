import React from 'react';
import ArticleGroup from '../resourceWrappers/ArticleGroup';
import OrganisationGroup from '../resourceWrappers/OrganisationGroup';
import VideoGroup from '../resourceWrappers/VideoGroup';

function ResultsLayout({articlesProps, videosProps, orgsProps}){
    
    var videos = videosProps? <VideoGroup videos={videosProps}/>:null
    var organisations = orgsProps? <OrganisationGroup orgs={orgsProps}/>:null
    var articles = articlesProps? <ArticleGroup articles={articlesProps}/>:null

    var main,side = null;


    if (articles && !videos){
      main = articles;
    }else if (articles){
      main = [videos,organisations];
      side = articles;
    } else if (videos){
      main = videos;
      side = organisations;
    } else {
      main = organisations;
      side = null;
    }

    return (
        <div className="results-container-inner">
          <div className="results-side">
            {side}
          </div>
          <div className="results-main">
            {main}
          </div>
        </div>
      );
}

export default ResultsLayout;