

const disjunctiveFacetsConfig = ["content_type","content_severity","content_issue","content_support","content_audience"]

const facetsConfig = {
    content_type: { type: "value" },
    content_severity: { type: "value" },
    content_issue: { type: "value" },
    content_support: { type: "value" },
    content_audience: { type: "value" }
}


export {disjunctiveFacetsConfig, facetsConfig};