import connector from './searchConnector.js'
import {facetsConfig, disjunctiveFacetsConfig} from './searchFacets.js'

const searchConfig = {
  alwaysSearchOnInitialLoad: false,
  apiConnector: connector,
  searchQuery: {
    disjunctiveFacets: disjunctiveFacetsConfig,
    facets: facetsConfig
  },
  initialState: {
    resultsPerPage: 600
}
};

export default searchConfig;