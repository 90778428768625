import React from "react"
import TagButton from "../searchBar/TagButton";

// All code is heavily adapted from MultiCheckboxFacet in elasticSearch library as no documentation exists for custom facets
// @elastic//react-search-ui-views/src/MultiCheckboxFacet.js
function getFilterValueDisplay(filterValue) {
    if (filterValue === undefined || filterValue === null)
        return "";
    if (Object.prototype.hasOwnProperty.call(filterValue, "name"))
        return filterValue.name;
    return String(filterValue);
}

const CustomMultiFacetTags = ({ onRemove, onSelect, options, secondary}) => {
    return <>
    {
        options.length < 1 ? <div>No Matching Options</div> :
        options.map((option) => {
            const checked = option.selected;
            const value = option.value;

            const onChangeFunc = () => (checked ? onRemove(value) : onSelect(value))

            const text = (0, getFilterValueDisplay)(option.value)

            return <TagButton value={`${text}, ${option.count.toLocaleString("en")}`}
                              checked={checked}
                              onChangeFunc={onChangeFunc}
                              secondary={secondary} // boolean for non-primary tags
                              key={text}
                            />
        })
    }
</>


}


export default CustomMultiFacetTags