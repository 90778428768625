import React from 'react';
import {
  BrowserRouter, Route, Routes
} from "react-router-dom";
import SearchHandler from './components/search/SearchHandler';
import LandingPage from './components/LandingPage.js';


function App(props) {
  const location = window.location;

  const mainPage =  <div className="mainContainer">
                      <div className="mainTitle"> Miricyl </div>
                      <SearchHandler body={<LandingPage />}/>
                      </div>

  const headerOnly =    <div className="mainContainer">
                              <div className="minorTitle">
                                <a href="/search"> Miricyl </a>
                              </div>
                              <SearchHandler />
                         </div>

  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={mainPage}></Route>
        <Route exact path="/search/" element={mainPage}></Route>
        <Route path={location.pathname} element={headerOnly}></Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
