function getEmbedCode(url){
    //https://stackoverflow.com/questions/19377262/regex-for-youtube-url
    const regex = /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube(-nocookie)?\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/img;
    var matches = regex.exec(url);


    let embedCode = url;
    if (matches && (matches[6] && matches[6].length === 11)){
        embedCode = "https://www.youtube.com/embed/" + matches[6];
    } else{
        embedCode = "abc"
    }
    //TODO: Better error handling or using oembed api
    return embedCode
}

function VideoItem(props){

    return (
        <div className="video-item col-l-3" >
            <iframe title={props.content_video_description}  className="video"src={getEmbedCode(props.content_video_hyperlink)}></iframe>
            <div className="tags">
            {props.content_support?.map(tag=>{
                    return <span className="tag">  {tag} </span>})}
            </div>
            <div><a className="video-title" href={props.wordpress_url}> {props.content_video_description} </a></div> 
        </div>
    )
}

export default VideoItem;