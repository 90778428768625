import React from 'react';
import {useState}  from "react";


const ShowMoreWrapper = ({ initial, group, Component, message }) => {

    const [getAmount, setAmount] = useState(initial);

    const showMore = () =>{
        setAmount(getAmount + 10)
    }

    return <>
        {group.slice(0,getAmount).map(x=>
            <Component key={x.key} {...x}/>
        )}
        {  getAmount < group.length ? <button onClick={showMore}>Show More {message}👐</button> : null    }
    </>
}

export default ShowMoreWrapper