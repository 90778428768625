import React from 'react';
import OrganisationGroup from './resourceWrappers/OrganisationGroup';
import VideoGroup from './resourceWrappers/VideoGroup';


const videos =[
  {
      "content_video_description": "How to be Happy",
      "content_video_hyperlink": "https://www.youtube.com/embed/NmlWK-zCo6M",
      "key": "NmlWK-zCo6M",
      "content_support": ["Informational"],
      "content_video_summary": "Views: 12,143"
  },
  {
      "content_video_description": "Exercise & Depression",
      "content_video_hyperlink": "https://www.youtube.com/embed/TsF0hNfWN08",
      "key": "TsF0hNfWN08",
      "content_support": ["Informational"],
      "content_video_summary": "Views: 12,143"
  },
  {
      "content_video_description": "Maintaining Friendships",
      "content_video_hyperlink": "https://www.youtube.com/embed/o9ulSfCGMUs",
      "key": "o9ulSfCGMUs",
      "content_support": ["Informational"],
      "content_video_summary": "Views: 12,143"
  },
  {
      "content_video_description": "No Stress Guide",
      "content_video_hyperlink": "https://www.youtube.com/embed/eGVWRvNe1-A",
      "key": "eGVWRvNe1-A",
      "content_support": ["Informational"],
      "content_video_summary": "Views: 12,143"
  }
]


const serviceInfo = [
  {
    "content_org_name" : "Mind",
    "content_org_description" : "Advice and support to empower anyone experiencing a mental illness",
    "content_org_logo" : "https://navigateyourhealth.org/wp-content/org-logos/Mind.png",
    "content_org_home_page" : "https://www.mind.org.uk/",
    "content_org_email" :  "info@mind.org.uk",
    "content_org_phone_no" : "03001233393",
    "wordpress_url" : "http://navigateyourhealth.org/organisation/mind/"
  },
  {
    "content_org_name" : "NHS",
    "content_org_description" :     "Safe care saves lives - Be safe, be happy, be proud for everyone involved",
    "content_org_logo" :     "https://navigateyourhealth.org/wp-content/org-logos/NHS.png",
    "content_org_home_page" :   "https://www.nhs.uk/",
    "content_org_email" :  "helpdesk@nhs.net",
    "content_org_phone_no" :     "03003112233",
    "wordpress_url" : "http://navigateyourhealth.org/organisation/nhs/"
  },
  {
    "content_org_name" : "LGBT foundation",
    "content_org_description" :    "Join us in Standing up for Equality for LGBTQ+ members all over the world",
    "content_org_logo" :     "https://navigateyourhealth.org/wp-content/org-logos/LGBT%20Foundation.png",
    "content_org_home_page" :     "https://lgbt.foundation/",
    "content_org_email" :      "info@lgbt.foundation",
    "content_org_phone_no" :     "03453303030",
    "wordpress_url" : "http://navigateyourhealth.org/organisation/lgbt-foundation/"
  },
  {
    "content_org_name" : "Home-Start UK",
    "content_org_description" :    "Expert support helping families with young children under 5",
    "content_org_logo" :     "https://navigateyourhealth.org/wp-content/org-logos/Home-Start%20UK.png",
    "content_org_home_page" :     "https://www.home-start.org.uk/",
    "content_org_email" :      "info@home-start.org.uk",
    "content_org_phone_no" :     "01164645490",
    "wordpress_url" : "http://navigateyourhealth.org/organisation/home-start-uk/"
  },
  {
    "content_org_name" : "Gingerbread",
    "content_org_description" :    "Single parent advice line",
    "content_org_logo" :     "https://navigateyourhealth.org/wp-content/org-logos/Gingerbread.png",
    "content_org_home_page" :     "https://www.gingerbread.org.uk/",
    "content_org_email" :      "peersupport@gingerbread.org.uk",
    "content_org_phone_no" :     "08088020925",
    "wordpress_url" : "http://navigateyourhealth.org/organisation/gingerbread/"
  },
  {
    "content_org_name" : "CALM",
    "content_org_description" :    "A free, private and confidential service where you can talk about anything.",
    "content_org_logo" :     "https://navigateyourhealth.org/wp-content/org-logos/CALM.png",
    "content_org_home_page" :     "https://www.thecalmzone.net/",
    "content_org_email" :      "press@thecalmzone.net",
    "content_org_phone_no" :     "07738160434",
    "wordpress_url" : "http://navigateyourhealth.org/organisation/childline/"
  },
  {
    "content_org_name" : "Bullying UK",
    "content_org_description" :    "Support if you are being bullied in the workplace",
    "content_org_logo" :     "https://navigateyourhealth.org/wp-content/org-logos/Bullying%20UK.png",
    "content_org_home_page" :     "https://www.bullying.co.uk/",
    "content_org_email" :      "askus@familylives.org.uk",
    "content_org_phone_no" :     "08088002222",
    "wordpress_url" : "http://navigateyourhealth.org/organisation/bullying-uk/"
  },
  {
    "content_org_name" : "Anxiety UK",
    "content_org_description" :    "Support, advice and information for anxiety, stress and anxiety-based depression conditions. ",
    "content_org_logo" :     "https://navigateyourhealth.org/wp-content/org-logos/Anxiety%20UK.png",
    "content_org_home_page" :     "https://www.anxietyuk.org.uk/",
    "content_org_email" :      "support@anxietyuk.org.uk",
    "content_org_phone_no" :     "01612267727",
    "wordpress_url" : "http://navigateyourhealth.org/organisation/anxiety-uk/"
  },
  {
    "content_org_name" : "Action for Children",
    "content_org_description" :    "Down-to-earth parenting advice you can trust",
    "content_org_logo" :     "https://navigateyourhealth.org/wp-content/org-logos/Action%20for%20Children.png",
    "content_org_home_page" :     "https://www.actionforchildren.org.uk/",
    "content_org_email" :      "ask.us@actionforchildren.org.uk",
    "content_org_phone_no" :     "03001232112",
    "wordpress_url" : "http://navigateyourhealth.org/organisation/action-for-children/"
  },
]

const organisationsCopy = `There are a variety of organisations that can offer you help and support when you need it most. You can find a few of these below. Not seeing what you're looking for? Use the handy search bar to find services that suit your needs.
`

const tags = ["Addiction", "SelfHarm", "Suicide", "Anxiety", "Bullying", "Parenting", "LGBTQ+", "Depression"];


const LandingPage = (props) => {

    return <div className='landingPage'>

        <div className="topSection">
            {/* <!-- HEADINGS SECTION --> */}
          <div className="headings">
              <h1>Begin your journey to better emotional health</h1>
              <h5> 
              Everyone needs help with mental health sometimes. We’re here to make finding the right help easier. Whether you’re looking for urgent support, to simply improve your daily self-care or that of a loved one; we’re here to help you find the right resources, quickly and easily. To get started type in a topic.
              </h5>
              {/* <button>Take a questionnaire</button> */}
          </div>

        </div>

            {/* <!-- DIRECT LINK BUTTONS --> */}

            {/* Would be re-implemented with click to search wrapper component */}
            <div className="direct-link-buttons">
              {tags.map(tag=>
                <form method="get" action={`?`} key={tag}>
                  <input type="hidden" name="q" value={tag} /> 
                  <input type="hidden" name="size" value="n_600_n" /> 
                  <button className="btn-secondary" type="submit">{tag}</button>
                </form>
              )}
            </div>

        {/* <!-- RESOURCES SECTION --> */}
        <VideoGroup style={{backgroundColor:'white'}} videos={videos} hideCount={true}/>

        {/* <!-- SERVICES SECTION  -->  */}
        <OrganisationGroup orgs={serviceInfo} text={organisationsCopy} hideCount={true} initial={9}/>
    </div>
}

export default LandingPage