import FindHelpCard from "./FindHelpCard"
import ClickToSearchWrapper from "../utility/ClickToSearchWrapper"

export default function HelpModal(props) {




    return (<div id="find-help-container">
        <p>Select one</p>
        <div className="card-group">
            {props.findHelp.map(x => <ClickToSearchWrapper value={x.text} keyword={x.keyword} Component={FindHelpCard}/>)}
        </div>
        </div>
    )

}