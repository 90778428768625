import React from 'react'
import ArticleItem from './ArticleItem'

const ArticleGroup = (props) => {

    const articlesList = props.articles.map(article =>
                                <ArticleItem {...article} />
                            )

    return <div>
        <h4>Articles ({props.articles.length})</h4>
        {articlesList}
    </div>
}

export default ArticleGroup