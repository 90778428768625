function ensureArray(item){
    return Array.isArray(item)? item : [item]
}


// Takes the objects which are returned by elasticSearch and maps them to content specific component props
// i.e. map elastic fieldnames to props


function buildVideoProps(videos){
    return videos.map(x=>(
        {
            key:                        x.id.raw,
            content_video_description:  x.content_video_description?.raw,
            content_video_hyperlink:    x.content_video_hyperlink?.raw,
            wordpress_url:              x.url?.raw,
            content_support:            ensureArray(x.content_support?.raw),
            content_video_summary:      x.content_video_summary?.raw,
        }
    ))
}

function buildOrganisationProps(orgs){
    return orgs.map(x=>(
        {
            key:                        x.id.raw,
            wordpress_url:              x.url?.raw,
            content_org_name:           x.content_org_name?.raw,
            content_org_description:    x.content_org_description?.raw,
            content_org_home_page:      x.content_org_home_page?.raw,
            content_org_logo:           x.content_org_logo?.raw,
            content_org_phone_no:       x.content_org_phone_no?.raw,
            content_org_email:          x.content_org_email?.raw,
            content_org_opening_times:  x.content_org_opening_times?.raw,
            content_org_charity_no:     x.content_org_charity_no?.raw,
            content_org_google_place_id:x.content_org_google_place_id?.raw,
            content_org_facebook:       x.content_org_facebook?.raw,
            content_org_nation:         x.content_org_nation?.raw,
            content_org_priority:       x.content_org_priority?.raw
        }
    ))
}

function buildArticleProps(articles){
    return articles.map(x=>(
        {
            key:                        x.id.raw,
            content_article_title:      x.content_article_title?.raw,
            content_article_time_to_read:x.content_article_time_to_read?.raw,
            content_article_url:        x.content_article_url?.raw,
        }
    ))
}


export {buildVideoProps, buildOrganisationProps, buildArticleProps}