
import React from "react"
import CustomMultiFacetTags from "./CustomMultiFacetTags";

// All code is heavily adapted from MultiCheckboxFacet in elasticSearch library as no documentation exists for custom facets
// @elastic//react-search-ui-views/src/MultiCheckboxFacet.js
function CustomMultiFacet({ className, label, onMoreClick, onRemove, onSelect, options, showMore, showSearch, onSearch, searchPlaceholder, secondary}) {
    return <fieldset >
        <legend className="sui-facet__title">
            {label}
        </legend>
        <div className="sui-multi-checkbox-facet">
           <CustomMultiFacetTags onRemove={onRemove} onSelect={onSelect} options={options}/>
        </div>
    </fieldset>
    }


export default CustomMultiFacet