import {useState}  from "react";
import HelpModal from "./FindHelpModal";
import SearchBarModal from "./ImExperiencingModal";
import SearchBarInput from "./SearchBarInput";

function SearchBar(props) {
    // const profiles = props.profiles;
    // const experiences = props.experiences;

    const experiences = ["Anxiety", "Depression", "Anger", "Stress", "Sleep", "Bullying"];
    const profiles = ["All Ages", "18+", "LGBTQ+", "Children", "Carer", "Parental"];

    const findHelp = [{text: "I would like my mental health to improve", keyword: "Telephone services"},
                        {text: "I am concerned about social media", keyword: "Social Media"},
                        {text: "I have trouble with work, getting work or studying", keyword: "Work anxiety"},
                        {text: "I would like my housing situation to improve", keyword: "Housing"},
                        {text: "I would like my relationships with other people to improve", keyword: "Relationship advice"},
                        {text: "I would like my money situation to improve", keyword: "Financial help"}]
    

    const [experiencesShown, setExperiencesShown] = useState(false);
    const [helpShown, setHelpShown] = useState(false);

    const experiencesModal = () => {
        if (!experiencesShown && helpShown) { setHelpShown(false)};
        setExperiencesShown(!experiencesShown);
    }

    
    const findHelpModal = () => {
        if (!helpShown && experiencesShown) { setExperiencesShown(false)};
        setHelpShown(!helpShown);
    }

    return (<div className="search">
                {/* <button onClick={toggleLegacy} title="Custom theming is currently on the non-elastic searhbar.">ToggleSearchBars</button> */}
                <SearchBarInput experiencesModal={experiencesModal} findHelpModal={findHelpModal} {...props}/>
                { helpShown ? <HelpModal findHelp={findHelp} /> : null }
                { experiencesShown ? <SearchBarModal experiences={experiences} profiles={profiles}/> : null }
            </div>);
}



export default SearchBar;