import {
  ErrorBoundary, Results, SearchBox, SearchProvider, WithSearch
} from "@elastic/react-search-ui";
import "@elastic/react-search-ui-views/lib/styles/styles.css";
import React from 'react';
import searchConfig from '../data/searchConfig.js';
import Filtering from '../filtering/Filtering';
import SearchBar from '../searchBar/SearchBar.js';
import ResultsHandler from './ResultsHandler';

function SearchHandler(props) {


   return (
     // Next 3 lines are elasticsearch idiomatic so hard to understand
     //  https://github.com/elastic/search-ui/blob/1.10.0/ADVANCED.md#headless-core-reference
     // Basically Withsearch sets the context for the application, and it says:
     // Pick out the 3 states mentioned, mapping them to the same name
     // Then use them in a function (which is the rest of the app)
    <SearchProvider config={searchConfig}>
      <WithSearch mapContextToProps={({ wasSearched,isLoading,totalResults }) => ({ wasSearched,isLoading,totalResults })}>
        {({ wasSearched,isLoading,totalResults }) => {

          // var layout = {
          //   // Searchbox Components also offer support for autocomplete and result suggestion, check docs for props or older commits
          //   header: <SearchBox view={SearchBar} />
          // }
          
          let Body = props.body || <></>


          if (isLoading) {
            Body = <>Loading</>
          }

          if (wasSearched && totalResults === 0){
            Body = <>Sorry looks like we couldn't find resources for that search term</>
           }

          if (wasSearched && totalResults > 0){
           Body = <div className="resources-section results-container"  style={{background:"white"}}>
                    <Filtering/>
                    Based on our tagging, here are some resources which we feel could help
                    <Results view={ResultsHandler} />
                </div>
          }

          if (wasSearched && isLoading) {
            // For when tag selection changes
          }

          var searchContent=<>
            <SearchBox view={SearchBar}/>
            {Body}
          </>


          return (
              <ErrorBoundary>
                {searchContent}
              </ErrorBoundary>
          );
        }}
        
      </WithSearch>
    </SearchProvider>
  );

}

export default SearchHandler;