export default function FindHelpCard(props) {
    return (
        <label>
            <input type="radio" name="product" className="card-input-element" />
            <div className="card-input">
                <div className="card-body">
                    {props.value}
                </div>
            </div>
        </label>
    );
}